import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { type SerializeFrom } from '@remix-run/node'
import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { type IconType } from 'react-icons/lib'
import {
	PiBriefcase,
	PiIdentificationBadge,
	PiMagnifyingGlass,
	PiUsersThree,
} from 'react-icons/pi'
import { useDebounceValue } from 'usehooks-ts'
import Hero from '#app/components/hero'
import { Spinner } from '#app/components/spinner'
import { Badge } from '#app/components/ui/badge'
import { Button } from '#app/components/ui/button'
import { DataTable } from '#app/components/ui/data-table'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogTrigger,
} from '#app/components/ui/dialog'
import HeaderSimple from '#app/components/ui/header'
import { Input } from '#app/components/ui/input'
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from '#app/components/ui/tabs'
import { toast } from '#app/components/ui/use-toast'
import { columns as candidatesColumns } from '#app/routes/admin+/candidates+/index'
import { columns as usersColumns } from '#app/routes/admin+/users+/index'
import { columns as jobsColumns } from '#app/routes/app+/jobs+/index'
import { type loader as searchLoader } from '#app/routes/resources+/api+/search'
import { cn } from '#app/utils/misc'
import { titleize } from '#app/utils/string'

type SearchResultTabContentProps = {
	tab: 'jobs' | 'candidates' | 'employees'
	icon?: IconType | ReactNode
	isFetching: boolean
	results?: SerializeFrom<typeof searchLoader>
	children?: React.ReactNode
}

function SearchResultTabContent(props: SearchResultTabContentProps) {
	const { tab, icon, children, isFetching, results } = props
	const title = titleize(tab)

	return (
		<TabsContent
			value={tab}
			className="flex-grow overflow-y-auto data-[state=active]:flex data-[state=inactive]:hidden"
		>
			{isFetching ? (
				<Hero
					title={title}
					icon={<Spinner size={10} />}
					description="Searching, please wait..."
				/>
			) : (
				<>
					{!results?.jobs?.entries ? (
						<Hero
							title={title}
							icon={icon}
							description="Type in the search box above to find jobs."
						/>
					) : (
						<>
							{children ? (
								children
							) : (
								<Hero
									title={title}
									icon={icon}
									description="Coming soon..."
								/>
							)}
						</>
					)}
				</>
			)}
		</TabsContent>
	)
}

type TabContent = {
	results?: SerializeFrom<typeof searchLoader>
	icon?: IconType | ReactNode
}

function JobsContent({ results }: TabContent) {
	const data = results?.jobs
	if (!data?.entries || data.entries.length === 0) {
		return (
			<Hero
				title="Jobs"
				icon={PiBriefcase}
				description="No matching jobs found..."
			/>
		)
	}
	return (
		<DataTable
			data={data.entries}
			columns={jobsColumns}
			totalRows={
				data.metadata?.pagination?.total_count || data.entries.length
			}
			pageSize={25}
			paginationSettings={{
				pagesToShow: 0,
				showFirstButton: false,
				showLastButton: false,
				showNextButton: false,
				showPreviousButton: false,
			}}
		/>
	)
}

function CandidatesContent({ results, icon }: TabContent) {
	const data = results?.candidates
	if (!data?.entries || data.entries.length === 0) {
		return (
			<Hero
				title="Candidates"
				icon={icon}
				description="No matching candidates found..."
			/>
		)
	}
	return (
		<DataTable
			data={data.entries}
			columns={candidatesColumns}
			totalRows={
				data.metadata?.pagination?.total_count || data.entries.length
			}
			pageSize={25}
			paginationSettings={{
				pagesToShow: 0,
				showFirstButton: false,
				showLastButton: false,
				showNextButton: false,
				showPreviousButton: false,
			}}
		/>
	)
}

function EmployeeContent({ results, icon }: TabContent) {
	const data = results?.users
	if (!data?.entries || data.entries.length === 0) {
		return (
			<Hero
				title="Employees"
				icon={icon}
				description="No matching employees found..."
			/>
		)
	}
	return (
		<DataTable
			data={data.entries}
			columns={usersColumns}
			totalRows={
				data.metadata?.pagination?.total_count || data.entries.length
			}
			pageSize={25}
			paginationSettings={{
				pagesToShow: 0,
				showFirstButton: false,
				showLastButton: false,
				showNextButton: false,
				showPreviousButton: false,
			}}
		/>
	)
}

export function Search() {
	const [isSearchOpen, setIsSearchOpen] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [query, setQuery] = useState('')
	const [decouncedQuery, setDebouncedQuery] = useDebounceValue('', 500)
	const [selectedTab, setSelectedTab] = useState('jobs')
	const [results, setResults] = useState<
		SerializeFrom<typeof searchLoader> | undefined
	>(undefined)
	const numberFormatter = new Intl.NumberFormat(undefined)
	const inputRef = useRef<HTMLInputElement>(null)

	const openSearch = useCallback(() => {
		setIsSearchOpen(true)
	}, [])

	const closeSearch = useCallback(() => {
		setIsSearchOpen(false)
	}, [])

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			// Check if Ctrl+/ (or Cmd+/ on Mac) is pressed
			if ((event.ctrlKey || event.metaKey) && event.key === '/') {
				event.preventDefault()
				openSearch()
			}
		}

		window.addEventListener('keydown', handleKeyPress)

		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [openSearch])

	const handleSearch = useCallback(() => {
		async function fetchData() {
			setIsFetching(true)

			// build the query string
			const queryString = new URLSearchParams()
			queryString.set('query', decouncedQuery)

			const result = await fetch(
				`/resources/api/search?${queryString.toString()}`,
				{
					method: 'GET',
				},
			)
			const data = (await result.json()) as SerializeFrom<
				typeof searchLoader
			>
			setResults(data)

			// do we need to change the selected tab?
			const hasJobs =
				data?.jobs?.entries?.length && data.jobs.entries.length > 0
			const hasCandidates =
				data?.candidates?.entries?.length &&
				data.candidates.entries.length > 0
			const hasUsers =
				data?.users?.entries?.length && data.users.entries.length > 0
			const noResults = !hasJobs && !hasCandidates && !hasUsers

			// does the selected tab have results?
			let selectedTabHasResults = false
			if (selectedTab === 'jobs' && hasJobs) {
				selectedTabHasResults = true
			} else if (selectedTab === 'candidates' && hasCandidates) {
				selectedTabHasResults = true
			} else if (selectedTab === 'employees' && hasUsers) {
				selectedTabHasResults = true
			}

			// don't change anything if we don't have results or
			// the selected tab has results
			if (noResults || selectedTabHasResults) return

			// change the selected tab based to the first tab with results
			if (hasJobs) {
				setSelectedTab('jobs')
			} else if (hasCandidates) {
				setSelectedTab('candidates')
			} else if (hasUsers) {
				setSelectedTab('employees')
			}

			// bring focus back to the search input
			setTimeout(() => inputRef.current?.focus(), 1)
		}

		// fetch the data (async)
		fetchData()
			.catch((err) => {
				console.error(err)
				// show a toast
				toast({
					title: 'Error',
					description: `Failed to search, please try again later...`,
					variant: 'destructive',
				})
			})
			.finally(() => {
				setIsFetching(false)
			})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [decouncedQuery])

	useEffect(() => {
		if (decouncedQuery.length > 0) {
			handleSearch()
		}
	}, [decouncedQuery, handleSearch])

	return (
		<Dialog
			open={isSearchOpen}
			onOpenChange={(open) => {
				if (!open) {
					closeSearch()
				} else {
					openSearch()
				}
			}}
		>
			<DialogTrigger className="flex items-center gap-1" asChild>
				<Button
					variant={'outline'}
					className="h-10 w-10 border-transparent p-0 hover:border-primary hover:bg-primary/10 hover:text-primary"
					size={'sm'}
				>
					<PiMagnifyingGlass className="h-5 w-5" />
				</Button>
			</DialogTrigger>
			<DialogContent
				className={cn(
					'flex h-3/4 w-3/4 max-w-[75%] flex-col gap-0 overflow-hidden bg-white p-0',
				)}
			>
				<VisuallyHidden asChild>
					<DialogTitle>
						Search jobs, candidates and employees.
					</DialogTitle>
				</VisuallyHidden>
				<HeaderSimple
					title="Search"
					subtitle=""
					className="items-center pr-10"
				/>
				<div className="relative flex flex-none items-center border-b border-border">
					<Input
						type="query"
						autoFocus
						value={query}
						onChange={(e) => {
							setQuery(e.target.value)
							setDebouncedQuery(e.target.value)
						}}
						placeholder="Search jobs, candidates and employees."
						className="rounded-none border-none py-6 pl-5 pr-[150px] ring-0 focus-visible:ring-primary focus-visible:ring-opacity-50"
						disabled={isFetching}
						ref={inputRef}
					/>
					<Button
						className="absolute right-3"
						size={'sm'}
						disabled={isFetching}
						onClick={handleSearch}
					>
						{isFetching ? (
							<Spinner size={5} />
						) : (
							<PiMagnifyingGlass className="h-5 w-5" />
						)}
						<span className="flex leading-none">Search</span>
					</Button>
				</div>
				<Tabs
					value={selectedTab}
					onValueChange={setSelectedTab}
					className="m-0 flex flex-grow flex-col overflow-hidden"
				>
					<TabsList>
						<TabsTrigger
							value="jobs"
							className="items-center gap-x-2"
						>
							Jobs
							{results?.jobs?.entries?.length &&
							results.jobs.entries.length > 0 ? (
								<Badge
									variant="gray"
									className="inline-flex px-2.5 py-0.5 text-sm"
								>
									{numberFormatter.format(
										results.jobs.entries.length,
									)}
								</Badge>
							) : null}
						</TabsTrigger>
						<TabsTrigger
							value="candidates"
							className="items-center gap-x-2"
						>
							Candidates
							{results?.candidates?.entries?.length &&
							results.candidates.entries.length > 0 ? (
								<Badge
									variant="gray"
									className="inline-flex px-2.5 py-0.5 text-sm"
								>
									{numberFormatter.format(
										results.candidates.entries.length,
									)}
								</Badge>
							) : null}
						</TabsTrigger>
						<TabsTrigger
							value="employees"
							className="items-center gap-x-2"
						>
							Employees
							{results?.users?.entries?.length &&
							results.users.entries.length > 0 ? (
								<Badge
									variant="gray"
									className="inline-flex px-2.5 py-0.5 text-sm"
								>
									{numberFormatter.format(
										results.users.entries.length,
									)}
								</Badge>
							) : null}
						</TabsTrigger>
					</TabsList>
					<SearchResultTabContent
						tab="jobs"
						icon={PiBriefcase}
						isFetching={isFetching}
						results={results}
						children={
							<JobsContent results={results} icon={PiBriefcase} />
						}
					/>
					<SearchResultTabContent
						tab="candidates"
						icon={PiUsersThree}
						isFetching={isFetching}
						results={results}
						children={
							<CandidatesContent
								results={results}
								icon={PiUsersThree}
							/>
						}
					/>
					<SearchResultTabContent
						tab="employees"
						icon={PiIdentificationBadge}
						isFetching={isFetching}
						results={results}
						children={
							<EmployeeContent
								results={results}
								icon={PiUsersThree}
							/>
						}
					/>
				</Tabs>
			</DialogContent>
		</Dialog>
	)
}
