import { Link, useLocation } from '@remix-run/react'
import { type IconType } from 'react-icons/lib'
import { PiBriefcase, PiMailbox } from 'react-icons/pi'
import { IconProgress } from '#app/components/logo'
import { Search } from '#app/components/search/search-global'
import { cn } from '#app/utils/misc'
import { type UserConfidential } from '#types/api-client.types.gen'
import ProfileMenu from './profile-menu'

type DesktopNavbarProps = {
	user?: UserConfidential
	userPicture?: string
}

type NavigationItem = {
	name: string
	href: string
	icon: IconType
	current: boolean
}

export function DesktopNavbar(props: DesktopNavbarProps) {
	const location = useLocation()
	// const params = useParams()

	// destructure props
	const { userPicture, user } = props

	// main navigation buttons
	let navigation: NavigationItem[] = [
		{
			name: 'Jobs',
			href: '/app/jobs',
			icon: PiBriefcase,
			current: false,
		},

		{
			name: 'Sequence Templates',
			href: '/app/sequence-templates',
			icon: PiMailbox,
			current: false,
		},
	]

	// update to set the current value (based on the current route)
	navigation = navigation.map((item) => {
		item.current = location.pathname.startsWith(item.href)
		return item
	})

	// little hack needed for the top level (aka home)
	// navigation[0].current = location.pathname === navigation[0].href

	return (
		<div className="flex flex-col border-r bg-white px-2 shadow-sm dark:bg-gray-900">
			<div className="flex h-16 flex-none shrink-0 items-center justify-center">
				<IconProgress />
			</div>
			<nav className="mt-2 flex flex-grow">
				<ul className="flex flex-col items-center space-y-2">
					{navigation.map((item) => (
						<li key={item.name} className="flex">
							<Link
								to={item.href}
								title={item.name}
								className={cn(
									'cursor-pointer rounded-lg border-2 border-transparent px-2 py-2 font-medium leading-6 transition-colors',
									item.current
										? 'border-primary bg-primary/10 text-primary dark:bg-gray-800'
										: 'text-text-gray hover:bg-primary/10 hover:text-primary dark:hover:bg-gray-800',
								)}
							>
								<item.icon className="h-6 w-6 shrink-0" />
							</Link>
						</li>
					))}
				</ul>
			</nav>
			<nav className="mb-3 flex flex-none flex-col items-center space-y-5">
				<Search />
				<ProfileMenu userPicture={userPicture} user={user} />
			</nav>
		</div>
	)
}
