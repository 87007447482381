import { invariant } from '@epic-web/invariant'
import { json, type LoaderFunctionArgs } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import GenericErrorBoundary from '#app/components/error-boundary'
import { DesktopNavbar } from '#app/components/navbar/desktop'
import NewVersion from '#app/components/new-version'
import { useToast } from '#app/components/toaster'
import { EpicToaster } from '#app/components/ui/sonner'
import { useRootUserData, useTheme } from '#app/root'
import Unauthorized from '#app/routes/app+/unauthorized'
import { getUserPicture, requireUserId } from '#app/utils/auth.server'
import { combineHeaders } from '#app/utils/misc'
import { getToast } from '#app/utils/toast.server'
import { useVersionCheck } from '#app/utils/version-check'

export async function loader({ request }: LoaderFunctionArgs) {
	// user auth is required
	const userId = await requireUserId(request)
	invariant(userId, 'User ID is required')

	const { toast, headers: toastHeaders } = await getToast(request)
	const userPicture = await getUserPicture(request)

	return json(
		{
			userId,
			toast,
			userPicture,
		},
		{
			headers: combineHeaders(toastHeaders),
		},
	)
}

export default function AppLayout() {
	const data = useLoaderData<typeof loader>()
	const rootData = useRootUserData()
	const theme = useTheme()
	const { isNewVersionAvailable } = useVersionCheck()
	useToast(data.toast)

	if (!rootData?.user) {
		return <Unauthorized />
	}

	return (
		<>
			<div className="flex h-full flex-col overflow-hidden bg-[#FAFAFA] dark:bg-transparent">
				<NewVersion isVisible={isNewVersionAvailable} />

				<div className="flex flex-1 flex-grow flex-row overflow-hidden">
					{/* Static sidebar for desktop */}
					<DesktopNavbar
						userPicture={data.userPicture}
						user={rootData.user}
					/>

					<main className="relative flex-grow overflow-hidden">
						<Outlet />
					</main>
				</div>
			</div>
			<EpicToaster closeButton position="top-center" theme={theme} />
		</>
	)
}

// need the generic error boundary
export const ErrorBoundary = GenericErrorBoundary
